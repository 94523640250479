body {
  background-color: #f4f4f4;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
  color: inherit;
}

a:visited:hover {
  text-decoration: none;
}

html,
body,
#root {
  height: 100%;
  --topbar-height-desktop: 75px;
  --topbar-height-mobile: 50px;
}
